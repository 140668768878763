<template>
  <div class="home_box">
    <!-- 页面1 -->
    <div class="container">
      <div class="left">
        <div class="head_title">
          <span class="title_name">{{ title }}</span>
        </div>
        <div>
          <img src="@/assets/aboutwe.png" alt="" style="width: 70%;margin: 17%;">
        </div>
      </div>
      <div class="right">
        <div class="image">
          <img src="@/assets/phone.png" alt="手机图片" style="height:100%">
        </div>
      </div>
    </div>
    <!-- 页面2 -->
    <div class="container">
      <div class="infos">
        <img src="@/assets/img1.png" alt="">
        <span class="info_title">我们的愿景</span>
        <div class="description">
          <img src="@/assets/info2.png" alt="">
        </div>
      </div>
      <div class="infos">
        <img src="@/assets/img2.png" alt="">
        <span class="info_title">我们的使命</span>
        <div class="description">
          <img src="@/assets/info3.png" alt="">
        </div>
      </div>
    </div>
    <!-- 页面3 -->
    <div class="container">
      <div class="pages">
        <div class="pageHeader">
          <span>租拉部分页面展示</span>
        </div>
        <div class="images_box">
          <!-- 首页 -->
          <div class="images">
            <img src="@/assets/home.png" alt="">
            <span>租拉首页</span>
          </div>
          <!-- 我的 -->
          <div class="images">
            <img src="@/assets/myself.png" alt="">
            <span>租拉-我的</span>
          </div>
          <!-- 客服中心 -->
          <div class="images">
            <img src="@/assets/customerService.png" alt="">
            <span>租拉-客服中心</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 页面4 -->
    <div class="container">
      <div class="images_box">
        <!-- 首页 -->
        <div class="images">
          <img src="@/assets/alltype.png" alt="">
          <span>租拉-全部分类</span>
        </div>
        <!-- 我的 -->
        <div class="images">
          <img src="@/assets/modelNumber.png" alt="">
          <span>租拉-具体型号</span>
        </div>
        <!-- 客服中心 -->
        <div class="images">
          <img src="@/assets/orders.png" alt="">
          <span>租拉-订单追踪</span>
        </div>
      </div>
    </div>
    <!-- 页面5 -->
    <div class="container">
      <div class="left">
        <div class="head_title">
          <span class="phoneWe">联系我们</span>
        </div>
        <div class="info_box">
          <div class="label">
            <img src="../assets/icon1.png">
            <span class="title">官方邮箱：</span>
            <span class="info">zulaapp@sina.com</span>
          </div>
          <div class="label">
            <img src="../assets/icon2.png">
            <span class="title">官方热线：</span>
            <span class="info">400-9651098</span>
          </div>
          <div class="label" style="display:flex">
            <img src="../assets/icon3.png" style="height:28px">
            <div style="width:76%;margin-left:20px">
              <span class="title" style="margin-left:0">公司地址：</span>
              <span class="info">中国（杭州）西湖区双龙街199号金色西溪B座8楼866室</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="image">
          <img src="@/assets/logo.png" alt="" style="height:80%">
        </div>
      </div>
    </div>
    <!-- 底部信息 -->
    <div class="foot">
      <span>公司地址：中国（杭州）西湖区双龙街199号金色西溪B座8楼866室</span>
      <span>官方热线:400-9651098</span>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602013096"
        style="display:inline-block;text-decoration:none;height:25px;">
        <img src="@/assets/beian.png" style="float:left;" /><span
          style="float:left;height:25px;line-height:25px;margin: 0px 0px 0px 5px; color:#ffffff;">公网备案：浙公网安备
          33010602013096号</span>
      </a>
      <a target="_blank" href="http://beian.miit.gov.cn/"
        style="display:inline-block;text-decoration:none;height:25px;"><img src="" style="float:left;" /><span
          style="float:left;height:25px;line-height:25px;margin: 0px 0px 0px 5px; color:#ffffff;">ICP备案：浙ICP备2023013053号-1</span></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      title: "版图-租拉"
    }
  },
  methods: {
    iosDownload() {
      alert("开发中......")
    },
    androidDownload() {
      alert("开发中......")
    }
  }
}
</script>

<style lang="less" scoped>
.home_box {
  .foot {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #000000;
    padding-bottom: 20px;

    span {
      color: #ffffff;
      line-height: 25px;
    }
  }
}

.container {
  background: #000000;
  display: flex;
  height: 789px;
  width: 100%;
  // margin-bottom: 20px;
}

span {
  color: #ffffff;
}

// 页面1、5
.left {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .head_title {
    height: 20%;
    width: 100%;
    padding: 0 15px;

    .title_name {
      font-size: 70px;
      font-weight: 300;
    }

    .phoneWe {
      font-size: 25px;
      font-weight: bold;
      line-height: 157px;
      margin-left: 5%;
    }
  }

  .info_box {
    height: 50%;
    width: 87%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 20px 0;
    padding-left: 80px;

    .label {
      margin: 22px 15px;

      .info {
        font-size: 25px;
        font-weight: bold;
      }
    }

    span {
      font-size: 25px;
    }

    img {
      width: 25px;
    }

    .title {
      font-weight: bold;
      margin-left: 20px;
    }
  }
}

.right {
  width: 40%;
  height: 100%;

  .image {
    height: 80%;
    width: 80%;
    margin-top: 10%;
  }
}

// 页面2
.infos {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 291px;
  }

  .info_title {
    font-size: 25px;
    font-weight: 400;
    margin: 50px 0;
  }

  .description {
    img {
      height: 100px;
      width: 500px;
    }
  }
}

// 页面3
.pages {
  width: 100%;

  .pageHeader {
    width: 100%;
    height: 10%;

    span {
      line-height: 78px;
      font-size: 25px;
      margin-left: 7%;
    }
  }

  .images_box {
    display: flex;
    width: 100%;
    height: 80%;
    margin-top: 20px;
    justify-content: space-around;

    .images {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      span {
        font-size: 18px;
      }

      img {
        height: 650px;
      }
    }
  }
}

// 页面4
.images_box {
  display: flex;
  width: 100%;
  height: 90%;
  margin: auto 0;
  justify-content: space-around;

  .images {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    span {
      font-size: 18px;
    }

    img {
      height: 650px;
    }
  }
}
</style>

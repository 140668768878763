var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_box"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"head_title"},[_c('span',{staticClass:"title_name"},[_vm._v(_vm._s(_vm.title))])]),_vm._m(0)]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"70%","margin":"17%"},attrs:{"src":require("@/assets/aboutwe.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('div',{staticClass:"image"},[_c('img',{staticStyle:{"height":"100%"},attrs:{"src":require("@/assets/phone.png"),"alt":"手机图片"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"infos"},[_c('img',{attrs:{"src":require("@/assets/img1.png"),"alt":""}}),_c('span',{staticClass:"info_title"},[_vm._v("我们的愿景")]),_c('div',{staticClass:"description"},[_c('img',{attrs:{"src":require("@/assets/info2.png"),"alt":""}})])]),_c('div',{staticClass:"infos"},[_c('img',{attrs:{"src":require("@/assets/img2.png"),"alt":""}}),_c('span',{staticClass:"info_title"},[_vm._v("我们的使命")]),_c('div',{staticClass:"description"},[_c('img',{attrs:{"src":require("@/assets/info3.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"pages"},[_c('div',{staticClass:"pageHeader"},[_c('span',[_vm._v("租拉部分页面展示")])]),_c('div',{staticClass:"images_box"},[_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/home.png"),"alt":""}}),_c('span',[_vm._v("租拉首页")])]),_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/myself.png"),"alt":""}}),_c('span',[_vm._v("租拉-我的")])]),_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/customerService.png"),"alt":""}}),_c('span',[_vm._v("租拉-客服中心")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"images_box"},[_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/alltype.png"),"alt":""}}),_c('span',[_vm._v("租拉-全部分类")])]),_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/modelNumber.png"),"alt":""}}),_c('span',[_vm._v("租拉-具体型号")])]),_c('div',{staticClass:"images"},[_c('img',{attrs:{"src":require("@/assets/orders.png"),"alt":""}}),_c('span',[_vm._v("租拉-订单追踪")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"head_title"},[_c('span',{staticClass:"phoneWe"},[_vm._v("联系我们")])]),_c('div',{staticClass:"info_box"},[_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":require("../assets/icon1.png")}}),_c('span',{staticClass:"title"},[_vm._v("官方邮箱：")]),_c('span',{staticClass:"info"},[_vm._v("zulaapp@sina.com")])]),_c('div',{staticClass:"label"},[_c('img',{attrs:{"src":require("../assets/icon2.png")}}),_c('span',{staticClass:"title"},[_vm._v("官方热线：")]),_c('span',{staticClass:"info"},[_vm._v("400-9651098")])]),_c('div',{staticClass:"label",staticStyle:{"display":"flex"}},[_c('img',{staticStyle:{"height":"28px"},attrs:{"src":require("../assets/icon3.png")}}),_c('div',{staticStyle:{"width":"76%","margin-left":"20px"}},[_c('span',{staticClass:"title",staticStyle:{"margin-left":"0"}},[_vm._v("公司地址：")]),_c('span',{staticClass:"info"},[_vm._v("中国（杭州）西湖区双龙街199号金色西溪B座8楼866室")])])])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"image"},[_c('img',{staticStyle:{"height":"80%"},attrs:{"src":require("@/assets/logo.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot"},[_c('span',[_vm._v("公司地址：中国（杭州）西湖区双龙街199号金色西溪B座8楼866室")]),_c('span',[_vm._v("官方热线:400-9651098")]),_c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"25px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602013096"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":require("@/assets/beian.png")}}),_c('span',{staticStyle:{"float":"left","height":"25px","line-height":"25px","margin":"0px 0px 0px 5px","color":"#ffffff"}},[_vm._v("公网备案：浙公网安备 33010602013096号")])]),_c('a',{staticStyle:{"display":"inline-block","text-decoration":"none","height":"25px"},attrs:{"target":"_blank","href":"http://beian.miit.gov.cn/"}},[_c('img',{staticStyle:{"float":"left"},attrs:{"src":""}}),_c('span',{staticStyle:{"float":"left","height":"25px","line-height":"25px","margin":"0px 0px 0px 5px","color":"#ffffff"}},[_vm._v("ICP备案：浙ICP备2023013053号-1")])])])
}]

export { render, staticRenderFns }